import {createSlice} from "@reduxjs/toolkit";
import {initialUnitSelectorState, setUnitSelectorState} from "./utils";


export const unitSelectorSnapSlice = createSlice({
    name: 'unitSelectorSnap',
    initialState: initialUnitSelectorState,
    reducers: {
        setUnitSelectorSnaps: setUnitSelectorState,
    },
})

export const { setUnitSelectorSnaps } = unitSelectorSnapSlice.actions

export default unitSelectorSnapSlice.reducer