import React, {FC} from "react";
import {IconProps} from "./IconProps/IconProps";


export const DashboardIcon: FC<IconProps> = (props) => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        stroke="white"
        strokeWidth="2"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M1 3.25C1 2.00736 2.00736 1 3.25 1H5.5C6.74264 1 7.75 2.00736 7.75 3.25V5.5C7.75 6.74264 6.74264 7.75 5.5 7.75H3.25C2.00736 7.75 1 6.74264 1 5.5V3.25Z" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.25 3.25C12.25 2.00736 13.2574 1 14.5 1H16.75C17.9926 1 19 2.00736 19 3.25V5.5C19 6.74264 17.9926 7.75 16.75 7.75H14.5C13.2574 7.75 12.25 6.74264 12.25 5.5V3.25Z" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M1 14.5C1 13.2574 2.00736 12.25 3.25 12.25H5.5C6.74264 12.25 7.75 13.2574 7.75 14.5V16.75C7.75 17.9926 6.74264 19 5.5 19H3.25C2.00736 19 1 17.9926 1 16.75V14.5Z" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.25 14.5C12.25 13.2574 13.2574 12.25 14.5 12.25H16.75C17.9926 12.25 19 13.2574 19 14.5V16.75C19 17.9926 17.9926 19 16.75 19H14.5C13.2574 19 12.25 17.9926 12.25 16.75V14.5Z" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);