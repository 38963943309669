import React, {FC} from "react";
import {IconProps} from "./IconProps/IconProps";


export const UsersIcon: FC<IconProps> = (props) => (
    <svg
        width="18" height="21" viewBox="0 0 18 21" fill="none" stroke="white" strokeWidth="2" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M1 20C1 15.9192 4.58172 12.6111 9 12.6111C13.4183 12.6111 17 15.9192 17 20M13.5714 5.22222C13.5714 7.55409 11.5247 9.44444 9 9.44444C6.47527 9.44444 4.42857 7.55409 4.42857 5.22222C4.42857 2.89035 6.47527 1 9 1C11.5247 1 13.5714 2.89035 13.5714 5.22222Z" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);