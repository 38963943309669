import { NotificationInstance } from "antd/lib/notification";

function handleApiError(error: string, api: NotificationInstance) {
    api.open({
        message: `${error}`,
        placement: 'bottom',
        type: 'error',
    })
}

export default handleApiError;