import React, {FC} from "react";
import {IconProps} from "./IconProps/IconProps";


export const TriggersIcon: FC<IconProps> = (props) => (
    <svg
        width="23" height="23" viewBox="0 0 23 23" fill="none" stroke="white" strokeWidth="2" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M19.9756 22L16.8495 18.8568M3.02399 22L6.15017 18.8568M11.4998 8.54753V12.2164L13.2457 13.9509M5.13404 1.71841C4.67989 1.25971 4.06393 1.00202 3.42166 1.00202C2.7794 1.00202 2.16344 1.25971 1.70929 1.71841C1.25514 2.1771 1 2.79923 1 3.44792C1 4.09662 1.25514 4.71874 1.70929 5.17744M21.2907 5.17542C21.7449 4.71672 22 4.0946 22 3.4459C22 2.79721 21.7449 2.17508 21.2907 1.71639C20.8366 1.25769 20.2206 1 19.5783 1C18.9361 1 18.3201 1.25769 17.866 1.71639M19.9756 12.2164C19.9756 16.9443 16.1809 20.777 11.4998 20.777C6.81875 20.777 3.02399 16.9443 3.02399 12.2164C3.02399 7.48847 6.81875 3.65573 11.4998 3.65573C16.1809 3.65573 19.9756 7.48847 19.9756 12.2164Z" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);