import { IUser } from "../shared/lib/types";
import React, { useEffect, useState } from "react";
import { notification } from "antd";
import { directus } from "shared/api/directusConfig";
import { AuthContext } from "app/providers/context";
import Sidebar from "components/Sidebar/Sidebar";
import { MyAppRouter } from "app/providers/router";
import MySpin from "shared/ui/MySpin/MySpin";
import { HelmetProvider } from 'react-helmet-async';
import './styles/App.less';
import handleApiError from "shared/lib/handlers/errorHandler";


function App() {

    const [isAuth, setIsAuth] = useState(false);
    const [loading, setLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState<IUser>();
    const [api, contextHolder] = notification.useNotification();


    useEffect(() => {
        directus.auth.refresh()
            .then(() => {
                    directus.users.me.read()
                        .then((result) => {
                                setCurrentUser(result as IUser)
                            },
                            (error) => {
                                handleApiError(error, api)
                            })
                    setIsAuth(true)
                },
                () => {
                    setIsAuth(false)
                })

            .finally(() => setLoading(false))

    }, [])



    if (loading) {
        return <MySpin/>
    }


  return (
      <>
          <HelmetProvider>
              <AuthContext.Provider value={{
                  isAuth,
                  setIsAuth,
                  currentUser,
                  setCurrentUser
              }}>

                  {isAuth ?
                      <Sidebar/>
                      :
                      <MyAppRouter/>
                  }

              </AuthContext.Provider>
          </HelmetProvider>

          {contextHolder}
      </>
  );
}

export default App;
