import {createSlice} from "@reduxjs/toolkit";
import {initialUnitSelectorState, setUnitSelectorState} from "./utils";


export const unitSelectorTaskSlice = createSlice({
    name: 'unitSelectorTasks',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState: initialUnitSelectorState,
    reducers: {
        setUnitSelectorTasks: setUnitSelectorState,
    },
})


export const { setUnitSelectorTasks } = unitSelectorTaskSlice.actions

export default unitSelectorTaskSlice.reducer
