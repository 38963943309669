import {LocalStorage} from "@directus/sdk"

/**
 * Максимальный размер токена API в байтах, в соответствии с которым
 * устанавливается размер буфера для передачи токена между потоками.
 */
const MAX_TOKEN_SIZE_IN_BYTES = 1024 * 4

/**
 * Хранилище, которое используется для передачи токена через SharedArrayBuffer.
 *
 * При установке поля 'auth_token' значение сохраняется в буфер,
 * который может считываться в ReceiverSharedTokenStorage.
 */
class SourceSharedTokenStorage extends LocalStorage {
    public authTokenBuffer = new SharedArrayBuffer(MAX_TOKEN_SIZE_IN_BYTES);
    private authTokenBufferView = new Uint8Array(this.authTokenBuffer);
    private textEncoder = new TextEncoder();

    private updateAuthTokenBuffer(value: string) {
        const arrayBufferView = this.textEncoder.encode(value);
        this.authTokenBufferView.fill(0);
        this.authTokenBufferView.set(arrayBufferView);
    }

    set(key: string, value: string) {
        if (key === 'auth_token') {
            this.updateAuthTokenBuffer(value);
        }
        return super.set(key, value);
    }
}

export default SourceSharedTokenStorage;
