import {MemoryStorage} from "@directus/sdk"

/**
 * Хранилище, используемое для получения токена через SharedArrayBuffer.
 *
 * При попытке чтения поля 'auth_token' считывает и возвращает строку из буфера.
 */
class ReceiverSharedTokenStorage extends MemoryStorage {
    public authTokenBuffer?: SharedArrayBuffer;
    private authTokenBufferView?: Uint8Array;
    private authTokenDecoder = new TextDecoder();

    setAuthTokenBuffer(authTokenBuffer: SharedArrayBuffer) {
        this.authTokenBuffer = authTokenBuffer;
        this.authTokenBufferView = new Uint8Array(this.authTokenBuffer);
    }

    get(key: string) {
        if (key === 'auth_token' && this.authTokenBufferView) {
            const nonSharedView = this.authTokenBufferView.slice();
            const paddedAuthToken = this.authTokenDecoder.decode(nonSharedView);
            return paddedAuthToken.replace(new RegExp(String.fromCharCode(0), 'g'), '');
        }
        return super.get(key);
    }
}

export default ReceiverSharedTokenStorage;
