/* eslint-disable no-restricted-globals */
/// <reference lib="WebWorker" />

import {Directus} from "@directus/sdk";
import {IApiTypes} from "shared/lib/types/IApi";
import SourceSharedTokenStorage from './SourceSharedTokenStorage';
import ReceiverSharedTokenStorage from './ReceiverSharedTokenStorage';

// Нам необходимо использовать API как в основном потоке, так и в воркерах. Для аутентификации
// используется токен, который периодически обновляется. Мы не можем просто передать токен
// в воркеры как строку и затем обновлять его из каждого воркера отдельно, т.к. каждый токен
// должен обновиться только один раз, а все последующие запросы должны использовать новый.
// Чтобы обойти это ограничение, мы передаём в воркеры SharedArrayBuffer, содержащий токен.
// Этот буфер обновляется в основном потоке и читается в воркерах при запросах.

const isWorker = typeof WorkerGlobalScope !== 'undefined'
    && typeof self !== 'undefined'
    && self instanceof WorkerGlobalScope;

type DirectusWithSharedTokenStorage = Omit<Directus<IApiTypes>, 'storage'> & {
    storage: SourceSharedTokenStorage | ReceiverSharedTokenStorage
}

export const directus = new Directus<IApiTypes>('https://lvs-cms.logexpert.ru', {
    auth: {
        mode:"json",
        autoRefresh: !isWorker
    },
    storage: isWorker
        ? new ReceiverSharedTokenStorage()
        : new SourceSharedTokenStorage()
}) as unknown as DirectusWithSharedTokenStorage
