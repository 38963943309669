import React, {FC} from "react";
import {IconProps} from "./IconProps/IconProps";


export const ToggleOpenIcon: FC<IconProps> = (props) => (
    <svg
        width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect y="16" width="22" height="3" fill="white"/>
        <rect y="8" width="16" height="3" fill="white"/>
        <rect width="22" height="3" fill="white"/>
    </svg>
)