import {Draft, PayloadAction} from "@reduxjs/toolkit";
import {IPrimaryKey, IUnit} from "../../../shared/lib/types";

export interface UnitSelectorState {
    label: string;
    value: IPrimaryKey;
    unit?: IUnit
}

// Define the initial state using that type
export const initialUnitSelectorState: UnitSelectorState = {
    label: 'Транспорт',
    value: -1,
}

export const setUnitSelectorState = (state: Draft<UnitSelectorState>, value: PayloadAction<UnitSelectorState>) => {
    state.value = value.payload.value
    state.label = value.payload.label
}