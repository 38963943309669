import "./Sidebar.css"
import React, {FC, useState} from 'react';
import { NavLink} from "react-router-dom";
import {ToggleClosedIcon} from "shared/assets/icons/ToggleClosedIcon";
import {ToggleOpenIcon} from "shared/assets/icons/ToggleOpenIcon";
import {Logo} from "shared/assets/icons/Logo";
import {Layout} from "antd";
import {privateRoutes} from "shared/config/routeConfig/routeConfig";
import 'antd/dist/antd.css';
import MyAppRouter from "app/providers/router/ui/MyAppRouter";
const { Content } = Layout;


const Sidebar: FC = () => {
    const [collapsed, setCollapsed] = useState<boolean>(false);


    const toggleSidebar = () => {
        (document.querySelector("body") as HTMLElement).classList.toggle("sidebar-collapsed");
        setCollapsed(!collapsed)
    }


    const isActiveCollapsed = ({isActive}:{isActive: boolean}) => {
        if (collapsed && isActive) {
            return {backgroundColor: 'var(--primary-color)'};
        } else if (!collapsed && isActive) {
            return {backgroundColor: 'var(--primary-color-dark)'};
        }
    }

    return (
        <div className="container">
            <aside className="sidebar">
                <div className='sidebar__header-menu'>
                    <div className="sidebar__header row-align-center">
                            <div className="sidebar__header__logo">
                                <Logo/>
                            </div>
                            <div className="icon" onClick={toggleSidebar}>
                                {collapsed ?
                                    <ToggleClosedIcon/>
                                    :
                                    <ToggleOpenIcon/>}
                            </div>
                    </div>
                    <nav>
                        <ul className="sidebar__menu">
                            {privateRoutes.map(item =>
                                <li key={item.key}>
                                    <NavLink
                                        to={item.path}
                                        className="sidebar__menu__item row-align"
                                        style={isActiveCollapsed}>
                                        <div className="sidebar__menu__item__icon">
                                            {item.icon}
                                        </div>
                                        <span className="sidebar__menu__item__text">{item.title}</span>
                                    </NavLink>
                                </li>
                            )}
                        </ul>
                    </nav>
                </div>
                <footer className="sidebar__footer">
                    { collapsed ?
                        <div className="sidebar__footer__text footer white-text">
                            <p>© 2022</p>
                            <p>«TrackBack»</p>
                            <p>ver. 1.8.7</p>
                        </div>
                        :
                        <p className="sidebar__footer__text footer white-text">© 2022 «TrackBack», ver. 1.8.7</p>
                    }
                </footer>
            </aside>
            <Content className="main" >
                <MyAppRouter/>
            </Content>
        </div>

    );
};
export default Sidebar;