import React, {useContext, Suspense} from 'react';
import {AuthContext} from "../../context";
import {Navigate, Route, Routes} from "react-router-dom";
import {privateRoutes, publicRoutes} from "shared/config/routeConfig/routeConfig";
import MySpin from "shared/ui/MySpin/MySpin";

const MyAppRouter = () => {
    const {isAuth} = useContext(AuthContext);

    return (
        <Suspense fallback={<MySpin/>}>

            {isAuth ?

                <Routes>
                    <Route path="/" element={<Navigate to="dashboard" replace/>}/>
                    {privateRoutes.map(route =>
                        <Route key={route.key} path={route.path} element={route.element}/>
                    )}
                    <Route path="*" element={<Navigate to="/" replace/>}/>
                </Routes>

                :

                <Routes>
                    <Route path="/" element={<Navigate to="login" replace/>}/>
                    {publicRoutes.map(route =>
                        <Route key={route.key} path={route.path} element={route.element}/>
                    )}
                    <Route path="*" element={<Navigate to="/" replace/>}/>
                </Routes>
            }
        </Suspense>
    );
};


export default MyAppRouter;