import React, {FC} from "react";
import {IconProps} from "./IconProps/IconProps";


export const StreamIcon: FC<IconProps> = (props) => (
    <svg
        width="23" height="23" viewBox="0 0 23 23" fill="none" stroke="white" strokeWidth="2" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M11.5 17.3333V22M6.83333 22H16.1667M6.6 17.3333H16.4C18.3602 17.3333 19.3403 17.3333 20.089 16.9519C20.7475 16.6163 21.283 16.0809 21.6185 15.4223C22 14.6736 22 13.6935 22 11.7333V6.6C22 4.63982 22 3.65972 21.6185 2.91103C21.283 2.25247 20.7475 1.71703 20.089 1.38148C19.3403 1 18.3602 1 16.4 1H6.6C4.63982 1 3.65972 1 2.91103 1.38148C2.25247 1.71703 1.71703 2.25247 1.38148 2.91103C1 3.65972 1 4.63982 1 6.6V11.7333C1 13.6935 1 14.6736 1.38148 15.4223C1.71703 16.0809 2.25247 16.6163 2.91103 16.9519C3.65972 17.3333 4.63982 17.3333 6.6 17.3333Z" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);