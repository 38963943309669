import React from 'react';
import App from './app/App';
import "app/styles/index.css"
import "antd/dist/antd.css";
import { BrowserRouter as Router } from 'react-router-dom';
import {Provider} from "react-redux";
import {store} from "app/store/store";
import {createRoot} from "react-dom/client";
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";

Sentry.init({
    dsn: "https://d431ba629d7b497c81d1c81d3cff198c@sentry.logexpert.ru/3",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});


const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <Provider store={store}>
        <Router>
            <App/>
        </Router>
    </Provider>);


