import React, {ReactElement, ReactNode} from "react";
import {DashboardIcon} from "shared/assets/icons/DashboardIcon";
import {TransportIcon} from "shared/assets/icons/TransportIcon";
import PhotoIcon from "shared/assets/icons/PhotoIcon";
import {TasksIcon} from "shared/assets/icons/TasksIcon";
import {TracksIcon} from "shared/assets/icons/TracksIcon";
import {StreamIcon} from "shared/assets/icons/StreamIcon";
import {TriggersIcon} from "shared/assets/icons/TriggersIcon";
import {UsersIcon} from "shared/assets/icons/UsersIcon";
import {DashboardPage} from "pages/Dashboard";
import {TriggersPage} from "pages/Triggers";
import {StreamPage} from "pages/Stream";
import {TracksPage} from "pages/Tracks";
import {TransportPage} from "pages/Transpost";
import {TasksPage} from "pages/Tasks";
import {LoginPage} from "pages/Login";
import {SnapshotsPage} from "pages/Snapshots";
import {UsersPage} from "pages/Users";


interface IRoute {
    key: number,
    title?: string,
    icon?: ReactElement<any, any>,
    path: string,
    element: ReactNode,
}


export enum RouteNames{
    DASHBOARD = 'dashboard',
    TRANSPORT = 'transport',
    SNAPSHOTS = 'snapshots',
    TASKS = 'tasks',
    TRACKS = 'tracks',
    STREAM = 'stream',
    TRIGGERS = 'triggers',
    USERS = 'users',
    LOGIN = "login",
}


export const privateRoutes: IRoute[] = [
    {
        key: 1,
        title: 'Дашборд',
        icon: <DashboardIcon/>,
        path: RouteNames.DASHBOARD,
        element: <DashboardPage/>,
    },
    {
        key: 2,
        title: 'Транспорт',
        icon: <TransportIcon width='20' height='20'/>,
        path: RouteNames.TRANSPORT,
        element: <TransportPage/>,
    },
    {
        key: 3,
        title: 'Снимки',
        icon: <PhotoIcon width='20' height='20' strokeWidth='1.7'/>,
        path: RouteNames.SNAPSHOTS,
        element: <SnapshotsPage/>,
    },
    {
        key: 4,
        title: 'Задания',
        icon: <TasksIcon/>,
        path: RouteNames.TASKS,
        element: <TasksPage/>,
    },
    {
        key: 5,
        title: 'Треки',
        icon: <TracksIcon width='20'/>,
        path: RouteNames.TRACKS,
        element: <TracksPage/>,
    },
    {
        key: 6,
        title: 'Трансляция',
        icon: <StreamIcon width='20' height='20'/>,
        path: RouteNames.STREAM,
        element: <StreamPage/>,
    },
    {
        key: 7,
        title: 'Триггеры',
        icon: <TriggersIcon width='20' height='20'/>,
        path: RouteNames.TRIGGERS,
        element: <TriggersPage/>,
    },
    {
        key: 8,
        title: 'Пользователи',
        icon: <UsersIcon width='20' height='20'/>,
        path: RouteNames.USERS,
        element: <UsersPage/>,
    },
]


export const publicRoutes: IRoute[] = [
    {
        key: 1,
        path: RouteNames.LOGIN,
        element: <LoginPage/>,
    },
]