import React, {FC} from "react";
import {IconProps} from "./IconProps/IconProps";


export const TracksIcon: FC<IconProps> = (props) => (
    <svg
        width="22" height="20" viewBox="0 0 22 20" fill="none" stroke="white" strokeWidth="2" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M7.66667 19L1.61421 15.9359C1.23778 15.7454 1 15.3558 1 14.9297V2.82029C1 1.98398 1.86924 1.44005 2.60801 1.81406L7.66667 4.375M7.66667 19L14.3333 15.625M7.66667 19V4.375M14.3333 15.625L19.392 18.1859C20.1308 18.5599 21 18.016 21 17.1797V5.07029C21 4.64417 20.7622 4.25462 20.3858 4.06406L14.3333 1M14.3333 15.625V1M14.3333 1L7.66667 4.375" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);