import React, {FC} from "react";
import {IconProps} from "./IconProps/IconProps";


export const TasksIcon: FC<IconProps> = (props) => (
    <svg
        width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="white" strokeWidth="2" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M17 9H3M17 9C18.1046 9 19 9.89543 19 11V17C19 18.1046 18.1046 19 17 19H3C1.89543 19 1 18.1046 1 17V11C1 9.89543 1.89543 9 3 9M17 9V7C17 5.89543 16.1046 5 15 5M3 9V7C3 5.89543 3.89543 5 5 5M5 5V3C5 1.89543 5.89543 1 7 1H13C14.1046 1 15 1.89543 15 3V5M5 5H15" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);