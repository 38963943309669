import React, {FC} from 'react';
import {IconProps} from "./IconProps/IconProps";


const PhotoIcon: FC<IconProps> = (props) => {
    return (
        <svg
            width="17" height="15" viewBox="0 0 17 15" strokeWidth="1.5" stroke="white" fill="none" xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M1 4.90625C1 4.04331 1.74619 3.34375 2.66667 3.34375H3.44136C3.99862 3.34375 4.519 3.08265 4.82811 2.64797L5.50522 1.69578C5.81433 1.2611 6.33472 1 6.89197 1H10.108C10.6653 1 11.1857 1.2611 11.4948 1.69578L12.1719 2.64797C12.481 3.08265 13.0014 3.34375 13.5586 3.34375H14.3333C15.2538 3.34375 16 4.04331 16 4.90625V11.9375C16 12.8004 15.2538 13.5 14.3333 13.5H2.66667C1.74619 13.5 1 12.8004 1 11.9375V4.90625Z" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11 8.03125C11 9.32567 9.88071 10.375 8.5 10.375C7.11929 10.375 6 9.32567 6 8.03125C6 6.73683 7.11929 5.6875 8.5 5.6875C9.88071 5.6875 11 6.73683 11 8.03125Z" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default PhotoIcon;